::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border-radius: 6px;
}

.scrollbar-firefox {
  scrollbar-width: thin;
  scrollbar-color: #C1C1C1 #f1f1f1;
}