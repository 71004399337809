@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Raleway:wght@100;300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #2d3ac7; 
  --secondary-color: #ffffff; 
}



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer{ 
  padding: 0 !important;
}

@media(max-width:2560px){
  html{
    font-size: 17.78px;
  }
}

@media(max-width:1921px){
  html{
    font-size: 13.3px;
  }
}

@media(max-width:1800px){
  html{
    font-size: 12.5px;
  }
}

@media(max-width:1750px){
  html{
    font-size: 12.15px;
  }
}

@media(max-width:1700px){
  html{
    font-size: 11.8px;
  }
}


@media(max-width:1600px){
  html{
    font-size: 11.1px;
  }
}


@media(max-width:1500px){
  html{
    font-size: 10.417px;
  }
}

@media(max-width:1440px){
  html{
    font-size: 10px;
  }
}

@media(max-width:1300px){
  html{
    font-size: 9.03px;
  }
}

@media(max-width:1200px){
  html{
    font-size: 8.3px;
  }
}